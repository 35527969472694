import React from "react";
export default function Experience() {
  return (
    <section
      className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800"
      id="experience"
    >
      <div className="container">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h3 className="mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold">
            イベント実績（出演者）
          </h3>

          <p className="max-w-2xl mx-auto text-[15px]">
            堀内孝雄・渡辺真知子・田川寿美・伍代夏子・八神純子・松崎しげる・森口博子・島谷ひとみ
            <br />
            ANZEN漫才・神奈月・平野ノラ・U字工事・ニッチェ・りんごちゃん・アンガールズ
            <br />
            ダンディ坂野・メイプル超合金・ミスターしゃちほこ・カミナリ・どぶろっく
            <br />
            るみるみ（ものまね）・浅井未歩（歌手）・嶋淳一（歌手）・深田るみ子（歌手）・南極料理人西村淳（講演）
            <br />
            ※その他多数実績あり。
          </p>
        </div>

        <div className="grid grid-cols-1 mt-8">
          <div className="relative after:content-[''] after:absolute after:top-0 ltr:md:after:right-0 ltr:md:after:left-0 rtl:md:after:left-0 rtl:md:after:right-0 after:w-px after:h-full md:after:m-auto after:border-s-2 after:border-dashed after:border-gray-200 dark:after:border-gray-700 ms-3 md:ms-0">
            <div className="ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-amber-500 md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 rtl:md:after:left-0 rtl:md:after:right-0 ltr:after:-left-9 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
              <div className="grid md:grid-cols-2">
                <div className="md:text-end md:me-8 relative">
                  {/* <img
                    src="/images/logos/facebook-logo-2019.png"
                    className="rounded-full h-9 w-9 md:ms-auto"
                    alt=""
                  /> */}
                  <h5 className="my-2 font-semibold text-lg">津別夏まつり</h5>
                  <h6 className="text-sm mb-0">2011年〜2019年</h6>
                </div>

                <div className="ltr:float-left rtl:float-right text-start md:ms-8 mt-6 md:mt-0">
                  <h5 className="title mb-1 font-semibold">旭川冬まつり</h5>
                  <p className="mt-3 mb-0 text-[15px]">2012年〜2020年</p>
                </div>
              </div>
            </div>

            <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-amber-500 md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 ltr:after:-left-9 rtl:md:after:left-0 rtl:md:after:right-0 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
              <div className="grid md:grid-cols-2">
                <div className="text-start md:ms-8 relative md:order-2">
                  {/* <img
                    src="/images/logos/google-logo.png"
                    className="rounded-full h-9 w-9 md:me-auto"
                    alt=""
                  /> */}
                  <h5 className="my-2 font-semibold text-lg">
                    清里町ふるさと産業まつり
                  </h5>
                  <h6 className="text-sm mb-0">2012年〜2019年</h6>
                </div>

                <div className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                  <h5 className="title mb-1 font-semibold">
                    北竜町ひまわりフェスタ
                  </h5>
                  <p className="mt-3 mb-0  text-[15px]">2013年〜2019年</p>
                </div>
              </div>
            </div>

            <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-amber-500 md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 ltr:after:-left-9 rtl:md:after:left-0 rtl:md:after:right-0 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
              <div className="grid md:grid-cols-2">
                <div className="md:text-end md:me-8 relative">
                  {/* <img
                    src="/images/logos/lenovo-logo.png"
                    className="rounded-full h-9 w-9 md:ms-auto"
                    alt=""
                  /> */}
                  <h5 className="my-2 font-semibold text-lg">紋別流氷まつり</h5>
                  <h6 className="text-sm mb-0">2013年〜2020年</h6>
                </div>

                <div className="ltr:float-left rtl:float-right text-start md:ms-8 mt-6 md:mt-0">
                  <h5 className="title mb-1 font-semibold">
                    某社新春パーティー
                  </h5>
                  <p className="mt-3 mb-0 text-[15px]">2016年〜2021年</p>
                </div>
              </div>
            </div>

            <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-amber-500 md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 ltr:after:-left-9 rtl:md:after:left-0 rtl:md:after:right-0 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
              <div className="grid md:grid-cols-2">
                <div className="text-start md:ms-8 relative md:order-2">
                  {/* <img
                    src="/images/logos/circle-logo.png"
                    className="rounded-full h-9 w-9 md:me-auto"
                    alt=""
                  /> */}
                  <h5 className="my-2 font-semibold text-lg">
                    伊達緑丘高校卒業式
                  </h5>
                  <h6 className="text-sm mb-0">2023年</h6>
                </div>

                <div className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                  <h5 className="title mb-1 font-semibold">
                    南富良野町かなやま湖湖水まつり
                  </h5>
                  <p className="mt-3 mb-0 text-[15px]">2023年</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
